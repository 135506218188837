<template>
  <legal-page-template :locale="locale">
    <main class="legal-page__content legal-page-content">
      <h1 class="legal-page-content__title">{{ $t('legal.termsOfUse.title') }}</h1>
      <i18n tag="div" path="legal.termsOfUse.entry" class="legal-page-content__description">
        <b>{{ $t('legal.termsOfUse.universkin') }}</b>
      </i18n>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfUse.firstArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfUse.firstArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.inTermsOfUseTermsIdentified') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>(I) {{ $t('legal.termsOfUse.terms') }}</b>
            {{ $t('legal.termsOfUse.termsDefinition') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>(II) {{ $t('legal.termsOfUse.personalData') }}</b>
            {{ $t('legal.termsOfUse.personalDataDefinition') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>(III) {{ $t('legal.termsOfUse.doctor') }}</b>
            {{ $t('legal.termsOfUse.doctorDefinition') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>(IV) {{ $t('legal.termsOfUse.platform') }}</b>
            <i18n tag="span" path="legal.termsOfUse.platformDefinition">
              <a class="legal-page-content__link" href="https://skinxs.com" target="_blank"
                >https://skinxs.com</a
              >
            </i18n>
          </p>

          <p class="legal-article-section__paragraph">
            <b>(V) {{ $t('legal.termsOfUse.questionnaire') }}</b>
            {{ $t('legal.termsOfUse.questionnaireDefinition') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>(VI) {{ $t('legal.termsOfUse.regulations') }}</b>
            {{ $t('legal.termsOfUse.regulationsDefinition') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>(VII) {{ $t('legal.termsOfUse.site') }}</b>
            <i18n tag="span" path="legal.termsOfUse.siteDefinition">
              <a class="legal-page-content__link" href="https://www.universkin.com" target="_blank"
                >www.universkin.com</a
              >
            </i18n>
          </p>

          <p class="legal-article-section__paragraph">
            <b>(VIII) {{ $t('legal.termsOfUse.user') }}</b>
            {{ $t('legal.termsOfUse.userDefinition') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfUse.secondArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfUse.secondArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.termsConstituteContact') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.byUsingSiteAndPlatform') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.universkinReservesRightToModify') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfUse.thirdArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfUse.thirdArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph legal-article-section__paragraph--bold">
            3.1 {{ $t('legal.termsOfUse.creationOfAccount') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.accessToPlatformRequires') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.doctorSendsUniqueHypertextLink') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.userThenInvitedToEnterTheirEmail') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.beforeValidatingIdentifiers') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.byClickingContinue') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.userThenAccessesQuestionnaire') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.universkinCannotUnderAnyCircumstances') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.loginCredentialsOfUser') }}
          </p>

          <i18n
            tag="p"
            path="legal.termsOfUse.inEventOfLossForgetfulness"
            class="legal-article-section__paragraph"
          >
            <i>{{ $t('legal.termsOfUse.forgottenPassword') }}</i>
          </i18n>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.inNoCaseUniverskinHeldResponsible') }}
          </p>

          <p class="legal-article-section__paragraph legal-article-section__paragraph--bold">
            3.2 {{ $t('legal.termsOfUse.equipment') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.accessToSiteAndPlatformRequires') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.allCostsRelatingToSiteAndPlatform') }}
          </p>

          <p class="legal-article-section__paragraph legal-article-section__paragraph--bold">
            3.3 {{ $t('legal.termsOfUse.availabilityOfPlatform') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.platformAccessible') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.universkinIsOnlyBoundByObligation') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.userExpresslyAcknowledgesThatAboveMentioned') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfUse.fourthArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfUse.fourthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.givenNatureAndPurposeOfPlatform') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.inCaseOfIncorrectInformation') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.inGeneralUserAgrees') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.itStrictlyForbidden') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.termsOfUse.exerciseOfIllegalDiscriminatoryFraudulent') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.termsOfUse.breachOfPublicOrder') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.termsOfUse.assistanceOfIncitement') }}
          </p>

          <p class="legal-article-section__paragraph">
            — {{ $t('legal.termsOfUse.andMoreGenerallyAnyPractice') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.universkinMayTerminateUsersAccess') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfUse.fifthArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfUse.fifthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.universkinMakesItsBestEfforts') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.siteAndPlatformHaveNoVocation') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.userSoleMasterOfProperUse') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.userInformedThatAccessToSiteAndPlatform') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.accessToAndOrUseOfSiteAndOrPlatformSole') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.siteAndPlatformMadeAvailable') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.toFullestExtentPermittedByApplicable') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.userAcknowledgesThatUniverskin') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.responsibilityOfUniverskin') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfUse.sixthArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfUse.sixthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <i18n
            tag="p"
            path="legal.termsOfUse.sitePlatformIncludingTheirArchitecture"
            class="legal-article-section__paragraph"
          >
            <b>{{ $t('legal.termsOfUse.protectedElements') }}</b>
          </i18n>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.useOfSiteAndOrPlatformNotInAnyWayConfer') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.itStrictlyForbiddenToRepresent') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.failureToComplyWithProvisions') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfUse.seventhArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfUse.seventhArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <i18n
            tag="p"
            path="legal.termsOfUse.universkinConcernedWithProtection"
            class="legal-article-section__paragraph"
          >
            <a
              class="legal-page-content__link"
              href="https://www.skinxs.com/patient-notice"
              target="_blank"
              >https://www.skinxs.com/patient-notice</a
            >
          </i18n>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfUse.eightArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfUse.eightArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.siteAndPlatformMayContain') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.userNotAuthorizedToCreate') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfUse.ninthArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfUse.ninthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.termsComeIntoForce') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.inCaseOfUseSiteAndOrPlatformByUser') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.userInformedThatUniverskinNotify') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfUse.tenthArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfUse.tenthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            <b>{{ $t('legal.termsOfUse.intuituPersonae') }}</b>
            {{ $t('legal.termsOfUse.termsConcludedIntuituPersonae') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>{{ $t('legal.termsOfUse.disability') }}</b>
            {{ $t('legal.termsOfUse.disabilityDescription') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>{{ $t('legal.termsOfUse.noWaiver') }}</b>
            {{ $t('legal.termsOfUse.noWaiverDescription') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>{{ $t('legal.termsOfUse.evidenceAgreement') }}</b>
            {{ $t('legal.termsOfUse.evidenceAgreementDescription') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfUse.eleventhArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfUse.eleventhArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.termsExecutedAndInterpreted') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfUse.partiesDeclareTheirIntention') }}
          </p>

          <i18n
            tag="p"
            path="legal.termsOfUse.notwithstandingAboveUserBenefits"
            class="legal-article-section__paragraph"
          >
            <a
              class="legal-page-content__link"
              href="https://medicys-consommation.fr/"
              target="_blank"
              >https://www.cnpm-mediation-consommation.eu/</a
            >
          </i18n>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfUse.twelfthArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfUse.twelfthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <i18n
            tag="p"
            path="legal.termsOfUse.forAnyInformationConcerning"
            class="legal-article-section__paragraph"
          >
            <a href="mailto:info@universkin.com" class="legal-page-content__link"
              >info@universkin.com</a
            >
          </i18n>
        </div>
      </div>
    </main>
  </legal-page-template>
</template>

<script>
import LegalPageTemplate from '@/components/templates/LegalPageTemplate';

export default {
  name: 'PageTerms',
  components: { LegalPageTemplate },
  props: {
    locale: {
      type: String,
      default: ''
    }
  },
  metaInfo: {
    title: 'Terms of Use',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/legal-pages/content-styles';
</style>
